import { Grid } from '@mui/material'

import { MedicalConditions } from './data'
import ButtonBox from 'src/components/button-box'

export default function DescribeYourConditionStepForm(props) {
  const { selectedConditions, handleSelectCondition } = props
  return (
    <Grid container spacing={2}>
      {MedicalConditions.map(option => (
        <Grid item xs={12} sm={6} md={6} key={`${option.key}`}>
          <ButtonBox
            icon
            color="secondary"
            selected={selectedConditions.indexOf(option.value) >= 0}
            onClick={() => {
              handleSelectCondition(option.value)
            }}
          >
            {option.label}
          </ButtonBox>
        </Grid>
      ))}
    </Grid>
  )
}
