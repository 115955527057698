import { doRequest } from 'src/lib/axios/axios-config'

export async function getAgencyInfo(sessionParamValue) {
  try {
    const buildUrl = ({ url, uuid, zz }) => {
      if (!uuid) {
        throw new Error('uuid is required')
      }
      if (zz) {
        return `${url}/${uuid}/?z=${zz}`
      }

      return `${url}/${uuid}`
    }

    const response = await doRequest.get({
      url: buildUrl({
        url: '/initialize',
        uuid: sessionParamValue?.i,
        zz: sessionParamValue?.z,
      }),
    })
    return response.data
  } catch (e) {
    // return e.response.data
    console.error('🚀 ~ file: agency-api.js ~ e', e?.response)
    return e
  }
}

export async function getAgencies(sessionParamValue) {
  try {
    const buildUrl = ({ url, groupId, zz }) => {
      if (!groupId) {
        throw new Error('uuid is required')
      }
      if (zz) {
        return `${url}/${groupId}/?z=${zz}`
      }

      return `${url}/${groupId}`
    }

    const response = await doRequest.get({
      url: buildUrl({
        url: '/initialize/group',
        groupId: sessionParamValue?.g,
        zz: sessionParamValue?.z,
      }),
    })
    return response.data
  } catch (e) {
    // return e.response.data
    console.error('🚀 ~ file: group-api.js ~ e', e?.response)
    return e
  }
}
