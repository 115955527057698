export const LeadSkills = [
  'Home Care Aide',
  'Companionship',
  'Cooking/meal preparation',
  'Light housekeeping',
  'Car transportation',
  "Doctor's appointment /errands",
  'Medication reminders',
  'Bath/shower',
  'Dress/grooming',
  'Cane/walker/wheelchair',
  'Toileting and adult diapers',
  'Gait belt',
  'Hoyer lift',
  "Dementia/Alzheimer's/Parkinson's",
  'Hospice care',
  'Registered Nurse',
  'Physical Therapy',
  'Occupational Therapy',
  'Speech Therapy',
]

export const CareOptions = [
  {
    label: 'Car Transport',
    value: 'Car Transport',
    key: 'car_transport',
  },
  {
    label: 'Companionship',
    value: 'Companionship',
    key: 'companionship',
  },
  {
    label: 'Cooking',
    value: 'Cooking',
    key: 'cooking',
  },
  {
    label: 'Doctor Appointment',
    value: 'Doctor Appointment',
    key: 'doctor_appointment',
  },
  {
    label: 'Dressing',
    value: 'Dressing',
    key: 'dressing',
  },
  {
    label: 'Bathing Grooming Hygiene',
    value: 'Bathing Grooming Hygiene',
    key: 'bathing_grooming_hygiene',
  },
  {
    label: 'Light House Keeping',
    value: 'Light House Keeping',
    key: 'light_house_keeping',
  },
  {
    label: 'Med Reminders',
    value: 'Med Reminders',
    key: 'med_reminders',
  },
  {
    label: 'Shopping',
    value: 'Shopping',
    key: 'shopping',
  },
  {
    label: 'Toileting',
    value: 'Toileting',
    key: 'toileting',
  },
  {
    label: 'Walking Assistance',
    value: 'Walking Assistance',
    key: 'walking_assistance',
  },
]

export const MedicalConditions = [
  {
    label: "Alzheimer's",
    value: "Alzheimer's",
    key: 'alzheimers',
  },
  {
    label: 'Bed Bound',
    value: 'Bed Bound',
    key: 'bed_bound',
  },
  {
    label: 'Cane/Walker',
    value: 'Cane/Walker',
    key: 'cane_walker',
  },
  {
    label: 'Dementia',
    value: 'Dementia',
    key: 'dementia',
  },
  {
    label: 'Fall Risk',
    value: 'Fall Risk',
    key: 'fall_risk',
  },
  {
    label: 'Gait Belt',
    value: 'Gait Belt',
    key: 'gait_belt',
  },
  {
    label: 'Hoyer Lift',
    value: 'Hoyer Lift',
    key: 'hoyer_lift',
  },
  {
    label: 'Hospice',
    value: 'Hospice',
    key: 'hospice',
  },
  {
    label: "Parkinson's",
    value: "Parkinson's",
    key: 'parkinsons',
  },
  {
    label: 'Incontinence',
    value: 'Incontinence',
    key: 'incontinence',
  },
  {
    label: 'Wears Briefs',
    value: 'Wears Briefs',
    key: 'wears_briefs',
  },
]

export const LeadTypeConfig = {
  CLIENT: {
    label: 'Client Lead',
    value: 'CLIENT',
    key: 'client-lead',
  },
  EMPLOYEE: {
    label: 'Caregiver / Job Lead',
    value: 'EMPLOYEE',
    key: 'caregiver-job-lead',
  },
  MESSAGE: {
    label: 'Message',
    value: 'MESSAGE',
    key: 'message',
  },
  FRANCHISEE: {
    label: 'Franchisee Lead',
    value: 'FRANCHISEE',
    key: 'franchisee',
  },
}

export const COUNTRY_ZIP_CODE_DETAILS = {
  USA: {
    zipCodeRegexp: /^\d{5}(-\d{4})?$/,
    mask: '#####',
    directionRegexp: /[0-9]/,
  },
  CANADA: {
    zipCodeRegexp: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
    mask: '#######',
    directionRegexp: /[A-Za-z0-9\s]/,
  },
}
