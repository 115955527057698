import { useSelector } from 'src/redux/store'
import CaregiverJobLeadForm from './caregiver-job-lead-form'
import ClientLeadForm from './client-lead-form'
import MessageForm from './message-form'
import FranchiseeForm from './franchisee-form'
import FindCaregiverForm from './find-caregiver-form'

function ActiveFormView(props) {
  const { formView, agencyInfo, onSubmit, loading } = props
  const { storedParams: sessionValue } = useSelector(
    state => state.storedParams
  )

  switch (formView) {
    case 'client-lead':
      return (
        <ClientLeadForm
          sessionValue={sessionValue}
          agencyInfo={agencyInfo}
          onSubmit={onSubmit}
          loading={loading}
        />
      )
    case 'caregiver-job-lead':
      return (
        <CaregiverJobLeadForm
          sessionValue={sessionValue}
          agencyInfo={agencyInfo}
          onSubmit={onSubmit}
          loading={loading}
        />
      )
    case 'message':
      return (
        <MessageForm
          sessionValue={sessionValue}
          agencyInfo={agencyInfo}
          onSubmit={onSubmit}
          loading={loading}
        />
      )
    case 'franchisee':
      return (
        <FranchiseeForm
          sessionValue={sessionValue}
          agencyInfo={agencyInfo}
          onSubmit={onSubmit}
          loading={loading}
        />
      )
    case 'find-caregiver':
      return (
        <FindCaregiverForm
          sessionValue={sessionValue}
          agencyInfo={agencyInfo}
          onSubmit={onSubmit}
          loading={loading}
        />
      )
    default:
      return (
        <ClientLeadForm
          sessionValue={sessionValue}
          agencyInfo={agencyInfo}
          onSubmit={onSubmit}
          loading={loading}
        />
      )
  }
}

export default ActiveFormView
