import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import MuiAlert from '@mui/material/Alert'

import InputField from 'src/components/form-fields/input-field'
import { getAgencyInfo } from 'src/api/agency-api'
import { dispatch, useSelector } from 'src/redux/store'
import { setSelectedAgency, setCurrentView } from 'src/slices/dashboard-slice'
import { setStoreParams } from 'src/slices/stored-params-slice'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function FindAgencyDialog({ isOpen, handleAction }) {
  const { storedParams } = useSelector(state => state.storedParams)
  const [response, setResponse] = React.useState(null)
  const [validForm, setValidForm] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [validFields, setValidFields] = React.useState({
    uuid: false,
  })
  const [formValues, setFormValues] = React.useState({
    uuid: null,
  })

  React.useEffect(() => {
    let validFormFlag = validFields.uuid
    setValidForm(validFormFlag)
  }, [formValues, validFields])

  const handleClose = React.useCallback(() => {
    setResponse(null)
    setValidForm(false)
    setLoading(false)
    handleAction()
  }, [handleAction])

  const handleValid = React.useCallback(
    (field, value) => {
      setValidFields({ ...validFields, [field]: value })
    },
    [validFields]
  )

  const handleSubmit = React.useCallback(
    event => {
      event.preventDefault()
      setLoading(true)
      setResponse(null)
      getAgencyInfo({
        ...storedParams,
        i: formValues?.uuid,
        z: storedParams?.z || '-99',
        ac: storedParams?.ac || '1',
      })
        .then(apiResp => {
          setLoading(false)
          setResponse(apiResp)
          if (apiResp?.error) return

          const newStoreValue = {
            i: apiResp?.agency_uuid,
            z: storedParams?.z || '-99',
            ac: storedParams?.ac,
            find_agency: 'true',
          }
          dispatch(setStoreParams({ ...newStoreValue }))
          dispatch(
            setSelectedAgency({
              agency_id: apiResp?.agency_uuid,
              agency_name: apiResp.agency_name,
              zip_mandatory: apiResp.zip_mandatory,
            })
          )
          dispatch(setCurrentView('agency-info'))
          handleClose()
        })
        .catch(error => {
          console.error(
            '🚀 ~ file: find-agency-dialog.js:61 ~ getAgencyInfo ~ error:',
            error
          )
          setLoading(false)
          setResponse({
            error: true,
            message: 'Agency not found.',
          })
        })
    },

    [formValues?.uuid, handleClose, storedParams]
  )

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Find Agency</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the Agency UUID to to view agency details.
        </DialogContentText>
        <Box p={1} />
        <InputField
          name="uuid"
          autoFocus
          margin="dense"
          id="uuid"
          label="Agency UUID"
          type="text"
          fullWidth
          required
          onValid={value => handleValid('uuid', value)}
          onChange={value => setFormValues({ ...formValues, uuid: value })}
        />
        {!loading && response?.error && (
          <Box pb={1} pt={1}>
            <Alert severity="error">{response?.message}</Alert>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          disabled={!validForm}
        >
          Get Agency
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
