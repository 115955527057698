import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider } from 'notistack'
import { ConfirmProvider } from 'material-ui-confirm'

import ThemeProvider from './theme'
import Router from './routes/routes'
import { store, persistor } from './redux/store'

function App() {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading="Loading..." persistor={persistor}>
          <ThemeProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ConfirmProvider>
                  <Router />
                </ConfirmProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  )
}

export default App
