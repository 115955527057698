import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  storedParams: null,
}

const slice = createSlice({
  name: 'storedParams',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },
    setStoreParams(state, action) {
      state.storedParams = action.payload
    },
  },
})

export const storedParamsReducer = slice.reducer

export const { startLoading, stopLoading, setStoreParams } = slice.actions
