import { doRequest } from 'src/lib/axios/axios-config'

export async function createCaregiver({ id, caregiver }) {
  try {
    const response = await doRequest.post({
      url: `/caregivers/search/${id}`,
      data: { ...caregiver },
    })
    return response.data
  } catch (e) {
    console.info('🚀 ~ file: caregivers-api.js:11 ~ createCaregiver ~ e:', e)
    // return e.response.data
    return e
  }
}
