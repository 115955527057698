import * as _ from 'lodash'
import PACKAGE from '../package.json'

import { PATH_DASHBOARD } from './routes/paths'

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.base // as '/app/base'

export const APPLICATION_NAME = _.startCase(_.lowerCase(PACKAGE.name))
export const APPLICATION_VERSION = PACKAGE.version
export const APPLICATION_DESCRIPTION = PACKAGE.description
export const APPLICATION_COPYRIGHT = '© 2024 Agent App'
export const APPLICATION_AUTHOR = PACKAGE.author
export const APPLICATION_KEYWORDS = PACKAGE.keywords
export const APPLICATION_THEME = 'default' // default, dark
export const APPLICATION_LANGUAGE = 'en' // en, de, fr, pt, zh, es, ru, ja, nl
export const APPLICATION_LAYOUT = 'default' // default, compact, mini
export const APPLICATION_SIDEBAR = 'full' // full, compact, mini
export const APPLICATION_SIDEBAR_VARIANT = 'default' // default, solid, transparent
export const APPLICATION_SIDEBAR_POSITION = 'left' // left, right
export const APPLICATION_SIDEBAR_FOOTER = true // true, false
export const APPLICATION_SIDEBAR_FOOTER_TEXT = 'Agent App' // true, false
export const APPLICATION_HEADER = 'fixed' // fixed, static
export const APPLICATION_HEADER_POSITION = 'relative' // relative, absolute
export const APPLICATION_HEADER_RESPONSIVE = true // true, false
export const APPLICATION_HEADER_SEARCH_HOVER = false // true, false
export const APPLICATION_HEADER_BREADCRUMBS = true // true, false
export const APPLICATION_HEADER_DARK_MODE = false // true, false
export const APPLICATION_HEADER_RESPONSIVE_BREAKPOINT = 'md' // xs, sm, md, lg, xl, xxl

export const APPLICATION_QUERY_PARAM_STORE = `${PACKAGE.name}-param-store-`
export const APPLICATION_REDUX_LOCAL_STORAGE_NAME = `${PACKAGE.name}-redux-`
