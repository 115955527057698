import React from 'react'
import { Button, Box, Typography } from '@mui/material'
import Iconify from 'src/components/iconify'

export default function ButtonBox({ children, ...props }) {
  const { selected = false, mb, icon } = props

  const handleClick = React.useCallback(() => {
    if (props.onClick) {
      props.onClick()
    }
  }, [props])

  return (
    <>
      <Button
        fullWidth
        size="large"
        variant={selected ? 'soft' : 'outlined'}
        onClick={handleClick}
        {...props}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          width={1}
        >
          {icon ? (
            <>
              <Iconify
                icon={selected ? 'mdi:radiobox-marked' : 'mdi:radiobox-blank'}
              />
              <Typography
                variant="button"
                color="inherit"
                sx={{ marginLeft: 1 }}
              >
                {children}
              </Typography>
            </>
          ) : (
            children
          )}
        </Box>
      </Button>
      {mb && <Box mb={mb} />}
    </>
  )
}
