import { doRequest } from 'src/lib/axios/axios-config'

export async function login({ username, password, role }) {
  try {
    const response = await doRequest.post({
      url: '/users/authenticate',
      data: { username, password, role },
    })
    return response.data
  } catch (e) {
    // return e.response.data
    console.error('🚀 ~ file: AuthApi.js ~ line 12 ~ e', e?.response)
    return e
  }
}

export async function changePassword({ old_password, new_password }) {
  try {
    const response = await doRequest.put({
      url: '/users/password',
      data: { old_password, new_password },
    })
    return response.data
  } catch (e) {
    // return e.response.data
    console.error('🚀 ~ file: AuthApi.js ~ line 26 ~ e', e?.response)
    return e
  }
}

export async function checkServerSession() {
  try {
    const response = await doRequest.get({
      url: '/support/myserversession',
    })
    return response.data
  } catch (e) {
    // return e.response.data
    console.error('🚀 ~ file: AuthApi.js ~ line 39 ~ e', e?.response)
    return e
  }
}
