import { IMaskInput } from 'react-imask'
import React from 'react'

const ZipMaskInputField = React.forwardRef(
  function ZipMaskInputField(props, ref) {
    const { onChange, mask, directionRegexp, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask={mask || '#####'}
        definitions={{
          '#': directionRegexp || /[0-9]/,
        }}
        inputRef={ref}
        unmask={true}
        onAccept={value =>
          onChange({
            target: { name: props.name, value, required: props?.required },
          })
        }
        overwrite
      />
    )
  }
)

export default ZipMaskInputField
