import React from 'react'
import { Box, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import PhoneMaskInputField from 'src/components/phone-mask-input-field'
import ZipMaskInputField from 'src/components/zip-mask-input-field'
import InputField from 'src/components/form-fields/input-field'
import { COUNTRY_ZIP_CODE_DETAILS } from './data'

export default function FranchiseeForm(props) {
  const { agencyInfo, sessionValue, onSubmit, loading } = props
  const [validForm, setValidForm] = React.useState(false)
  const [validFields, setValidFields] = React.useState({
    name: false,
    email: false,
    phone: false,
    city: false,
    state: false,
    zip: false,
    chat_transcript: false,
  })
  const [formValues, setFormValues] = React.useState({
    name: null,
    email: null,
    phone: null,
    city: null,
    state: null,
    zip: null,
    chat_transcript: null,
  })

  React.useEffect(() => {
    let validFormFlag =
      validFields.name ||
      validFields.email ||
      validFields.phone ||
      validFields.zip ||
      validFields.city ||
      validFields.state ||
      validFields.chat_transcript

    const fields = ['email', 'phone', 'zip']

    fields.forEach(field => {
      if (formValues[field]) {
        validFormFlag = validFormFlag && validFields[field]
      }
    })

    setValidForm(validFormFlag)
  }, [formValues, validFields])

  const handleValid = React.useCallback(
    (field, value) => {
      setValidFields({ ...validFields, [field]: value })
    },
    [validFields]
  )

  const handleSubmit = React.useCallback(
    event => {
      event.preventDefault()
      const data = new FormData(event.currentTarget)
      onSubmit({
        type: 'LEAD',
        data: {
          leads: {
            name: data.get('name'),
            email: data.get('email'),
            phone_number: data.get('phone'),
            zip_postal_code: data.get('zip'),
            city: data.get('city'),
            state: data.get('state'),
            chat_transcript: data.get('chat_transcript'),
            agency_id: agencyInfo.agency_id,
            lead_type: 'FRANCHISEE',
            params: sessionValue,
          },
        },
      })
    },
    [agencyInfo.agency_id, onSubmit, sessionValue]
  )

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <InputField
            name="name"
            label="Full Name"
            type="text"
            margin="none"
            errorMessage="Please enter a name"
            onValid={value => handleValid('name', value)}
            onChange={value => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            margin="none"
            name="email"
            label="Email"
            type="email"
            validRegex={/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/}
            errorMessage="Please enter a valid email"
            onValid={value => handleValid('email', value)}
            onChange={value => setFormValues({ ...formValues, email: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            margin="none"
            name="phone"
            label="Phone"
            type="tel"
            validRegex={/^[0-9]{10,}$/}
            errorMessage="Please enter a valid phone number"
            onValid={value => handleValid('phone', value)}
            onChange={value => setFormValues({ ...formValues, phone: value })}
            InputProps={{
              inputComponent: PhoneMaskInputField,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            name="city"
            label="City"
            type="text"
            margin="none"
            errorMessage="Please enter a city name"
            onValid={value => handleValid('city', value)}
            onChange={value => setFormValues({ ...formValues, city: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            name="state"
            label="State"
            type="text"
            margin="none"
            errorMessage="Please enter a State "
            onValid={value => handleValid('state', value)}
            onChange={value => setFormValues({ ...formValues, state: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            margin="none"
            name="zip"
            label={agencyInfo?.country === 'CANADA' ? "Postal Code" : "Zip Code"}
            type="text"
            validRegex={COUNTRY_ZIP_CODE_DETAILS[agencyInfo?.country]?.zipCodeRegexp}
            errorMessage={agencyInfo?.country === 'CANADA' ? "Please enter a valid Postal Code" : "Please enter a valid Zip Code"}
            onValid={value => handleValid('zip', value)}
            onChange={value => setFormValues({ ...formValues, zip: value })}
            InputProps={{
              inputComponent: ZipMaskInputField,
              inputProps: {
                mask: COUNTRY_ZIP_CODE_DETAILS[agencyInfo?.country]?.mask,
                directionRegexp: COUNTRY_ZIP_CODE_DETAILS[agencyInfo?.country]?.directionRegexp,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <InputField
            margin="none"
            name="chat_transcript"
            label="Chat Transcript"
            type="text"
            multiline
            rows={4}
            onChange={value =>
              setFormValues({ ...formValues, chat_transcript: value })
            }
            onValid={value => handleValid('chat_transcript', value)}
          />
        </Grid>
      </Grid>
      <Box mb={2} />
      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        disabled={!validForm}
      >
        Submit
      </LoadingButton>
    </Box>
  )
}
