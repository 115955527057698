import { useCallback, useEffect } from 'react'

import { PATH_DASHBOARD } from 'src/routes/paths'
import { useRouter, useSearchParams } from 'src/routes/hooks'
import { useSelector } from 'src/redux/store'

export default function GuestGuard({ children }) {
  const router = useRouter()

  const searchParams = useSearchParams()

  const returnTo = searchParams.get('returnTo') || PATH_DASHBOARD.root

  const { isAuthenticated } = useSelector(state => state.auth)

  const check = useCallback(() => {
    if (isAuthenticated) {
      router.replace(returnTo)
    }
  }, [isAuthenticated, returnTo, router])

  useEffect(() => {
    check()
  }, [check])

  return <>{children}</>
}
