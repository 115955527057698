import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export default function AutoCompleteField(props) {
  const {
    name,
    label,
    value = [],
    valid = true,
    errorMessage,
    onChange = () => {},
    onValid = () => {},
    options,
    ...other
  } = props
  const [inputValue, setInputValue] = React.useState(value)
  const [isValid, setValid] = React.useState(valid)

  const handleChange = React.useCallback(
    (event, newValue) => {
      onChange(newValue)
      setInputValue(newValue)
      if (newValue) {
        setValid(true)
        onValid(true)
      } else {
        if (isValid) {
          setValid(false)
          onValid(false)
        }
      }
    },
    [isValid, onChange, onValid]
  )

  return (
    <Autocomplete
      id={`auto-complete-field-${React.useId()}`}
      value={inputValue}
      onChange={handleChange}
      name={name}
      options={options}
      {...other}
      renderInput={params => (
        <TextField {...params} name={name} label={label} variant="outlined" />
      )}
    />
  )
}
