import React from 'react'
import { Box, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import PhoneMaskInputField from 'src/components/phone-mask-input-field'

import InputField from 'src/components/form-fields/input-field'

export default function MessageForm(props) {
  const { agencyInfo, sessionValue, onSubmit, loading } = props
  const [validForm, setValidForm] = React.useState(false)
  const [validFields, setValidFields] = React.useState({
    name: false,
    email: false,
    phone: false,
    note: false,
    chat_transcript: false,
  })
  const [formValues, setFormValues] = React.useState({
    name: null,
    email: null,
    phone: null,
    note: null,
    chat_transcript: null,
  })

  React.useEffect(() => {
    let validFormFlag =
      validFields.name ||
      validFields.email ||
      validFields.phone ||
      validFields.note ||
      validFields.chat_transcript

    const fields = ['email', 'phone']

    fields.forEach(field => {
      if (formValues[field]) {
        validFormFlag = validFormFlag && validFields[field]
      }
    })

    setValidForm(validFormFlag)
  }, [formValues, validFields])

  const handleValid = React.useCallback(
    (field, value) => {
      setValidFields({ ...validFields, [field]: value })
    },
    [validFields]
  )

  const handleSubmit = React.useCallback(
    event => {
      event.preventDefault()
      const data = new FormData(event.currentTarget)

      onSubmit({
        type: 'LEAD',
        data: {
          leads: {
            name: data.get('name'),
            email: data.get('email'),
            phone_number: data.get('phone'),
            message: data.get('note'),
            chat_transcript: data.get('chat_transcript'),
            agency_id: agencyInfo.agency_id,
            lead_type: 'MESSAGE',
            params: sessionValue,
          },
        },
      })
    },
    [agencyInfo.agency_id, onSubmit, sessionValue]
  )

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <InputField
            name="name"
            label="Full Name"
            type="text"
            margin="none"
            errorMessage="Please enter a name"
            onValid={value => handleValid('name', value)}
            onChange={value => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            margin="none"
            name="email"
            label="Email"
            type="email"
            validRegex={/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/}
            errorMessage="Please enter a valid email"
            onValid={value => handleValid('email', value)}
            onChange={value => setFormValues({ ...formValues, email: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            margin="none"
            name="phone"
            label="Phone"
            type="tel"
            validRegex={/^[0-9]{10,}$/}
            errorMessage="Please enter a valid phone number"
            onValid={value => handleValid('phone', value)}
            onChange={value => setFormValues({ ...formValues, phone: value })}
            InputProps={{
              inputComponent: PhoneMaskInputField,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            margin="none"
            name="note"
            label="Agent Note"
            type="text"
            multiline
            rows={4}
            onChange={value => setFormValues({ ...formValues, note: value })}
            onValid={value => handleValid('note', value)}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            margin="none"
            name="chat_transcript"
            label="Chat Transcript"
            type="text"
            multiline
            rows={4}
            onChange={value =>
              setFormValues({ ...formValues, chat_transcript: value })
            }
            onValid={value => handleValid('chat_transcript', value)}
          />
        </Grid>
      </Grid>
      <Box mb={2} />
      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        disabled={!validForm}
      >
        Submit
      </LoadingButton>
    </Box>
  )
}
