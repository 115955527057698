import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

import {
  APPLICATION_REDUX_LOCAL_STORAGE_NAME,
  APPLICATION_QUERY_PARAM_STORE,
} from 'src/config-global'
import { authReducer } from '../features/auth/slices'
import { dashboardReducer } from 'src/slices/dashboard-slice'
import { storedParamsReducer } from 'src/slices/stored-params-slice'

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: APPLICATION_REDUX_LOCAL_STORAGE_NAME,
  whitelist: [],
}

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: APPLICATION_REDUX_LOCAL_STORAGE_NAME,
  whitelist: [
    'isAuthenticated',
    'jwtToken',
    'userType',
    'fullName',
    'firstName',
    'lastName',
    'changePassword',
    'storedParams',
  ],
}

const paramPersistConfig = {
  key: 'params',
  storage: storageSession,
  keyPrefix: APPLICATION_QUERY_PARAM_STORE,
  whitelist: ['storedParams'],
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  dashboard: dashboardReducer,
  storedParams: persistReducer(paramPersistConfig, storedParamsReducer),
})

export default rootReducer
