import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import DrawerAppBar from './header'

export default function DashboardLayout() {
  return (
    <>
      <DrawerAppBar />
      <Box height={64} />
      <Outlet />
    </>
  )
}
