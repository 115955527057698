import { Box, Grid } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { StyledRoot, StyledSectionBg } from './styles'
import Logo from '../../components/logo'

export default function AuthLayout() {
  return (
    <>
      <StyledRoot>
        <StyledSectionBg>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4.5} lg={3.5} xl={2.5}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={1}
              >
                <Logo
                  sx={{
                    width: 155,
                    height: 35,
                  }}
                />
              </Box>
              <Outlet />
            </Grid>
          </Grid>
        </StyledSectionBg>
      </StyledRoot>
    </>
  )
}
