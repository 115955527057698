import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  jwtToken: null,
  userType: null,
  firstName: null,
  lastName: null,
  fullName: null,
  changePassword: false,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },
    doLogin(state) {
      state.isAuthenticated = true
    },
    doLogout(state) {
      state.isAuthenticated = false
      state.jwtToken = null
      state.userType = null
      state.firstName = null
      state.lastName = null
      state.fullName = null
      state.changePassword = false
      localStorage.clear()
      sessionStorage.clear()
      setTimeout(() => {
        window.location.reload()
      }, 100)
    },
    setJWTToken(state, action) {
      state.jwtToken = action.payload
    },
    setUserType(state, action) {
      state.userType = action.payload
    },
    setFirstName(state, action) {
      state.firstName = action.payload
    },
    setLastName(state, action) {
      state.lastName = action.payload
    },
    setFullName(state, action) {
      state.fullName = action.payload
    },
    setChangePassword(state, action) {
      state.changePassword = action.payload
    },
  },
})

export const authReducer = slice.reducer

export const {
  doLogin,
  doLogout,
  setJWTToken,
  setUserType,
  setFirstName,
  setLastName,
  setFullName,
  setChangePassword,
} = slice.actions
