import { doRequest } from 'src/lib/axios/axios-config'

export async function createLead({ leads }) {
  try {
    const response = await doRequest.post({
      url: '/leads',
      data: { ...leads },
      filterPayload: true,
    })
    return response.data
  } catch (e) {
    console.info('🚀 ~ file: lead-api.js:11 ~ createLead ~ e:', e)
    // return e.response.data
    return e
  }
}
