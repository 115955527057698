export function getFormHeader(formView) {
  switch (formView) {
    case 'client-lead':
      return 'Client Lead'
    case 'caregiver-job-lead':
      return 'Caregiver / Job Lead'
    case 'message':
      return 'Message'
    case 'find-caregiver':
      return 'Find Caregiver'
    case 'franchisee':
      return 'Franchisee Lead'
    default:
      return 'Client Lead'
  }
}

export function showCalendlyBookingButton(formView) {
  switch (formView) {
    case 'client-lead':
      return false
    case 'caregiver-job-lead':
      return false
    case 'message':
      return false
    case 'find-caregiver':
      return false
    case 'franchisee':
      return false
    default:
      return false
  }
}

export function getCalendlyButtonText(formView) {
  switch (formView) {
    case 'client-lead':
      return 'Appointment'
    case 'caregiver-job-lead':
      return 'Appointment'
    case 'message':
      return null
    case 'find-caregiver':
      return null
    case 'franchisee':
      return null
    default:
      return null
  }
}
