import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Link } from '@mui/material'

import AIDQUEST_LOGO from '../../assets/aidquest-logo.png'

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 240,
        height: 50,
        display: 'inline-flex',
        backgroundImage: `url(${AIDQUEST_LOGO})`,
        backgroundSize: 'cover',
        ...sx,
      }}
      {...other}
    ></Box>
  )

  if (disabledLink) {
    return logo
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

export default Logo
