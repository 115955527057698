import { useEffect, useCallback, useState } from 'react'

import { PATH_AUTH } from 'src/routes/paths'
import { useRouter } from 'src/routes/hooks/use-router'
import { useSelector } from 'src/redux/store'
import { checkServerSession } from 'src/api/auth-api'
import { dispatch as rDispatch } from 'src/redux/store'
import { setChangePassword } from 'src/features/auth/slices'

export default function AuthGuard({ children }) {
  const router = useRouter()

  const { isAuthenticated } = useSelector(state => state.auth)

  const [checked, setChecked] = useState(false)

  const check = useCallback(async () => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString()

      const loginPath = PATH_AUTH.login

      const href = `${loginPath}?${searchParams}`

      router.replace(href)
    } else {
      setChecked(true)
      const resp = await checkServerSession()
      rDispatch(setChangePassword(resp?.change_password))
      if (resp?.change_password) {
        router.push('/auth-protected/change-password')
      }
    }
  }, [isAuthenticated, router])

  useEffect(() => {
    check()
  }, [check])

  if (!checked) {
    return null
  }

  return children
}
