import axios from 'axios'

import { dispatch as rDispatch } from 'src/redux/store'
import filterPayloadData from 'src/utils/filter-payload'
import getQueryStringParams from 'src/utils/get-query-string-params'
import { API_TIMEOUT, BASE_API_URL } from './constant'
import {
  doLogin,
  doLogout,
  setJWTToken,
  setUserType,
  setFirstName,
  setLastName,
  setFullName,
} from 'src/features/auth/slices'

function AuthHandler({ dispatch, response, authObject }) {
  const token =
    response?.headers?.authorization || response?.headers?.Authorization
  const status = response?.status

  if (response) {
    if (token && !authObject?.isAuthenticated) {
      dispatch(setJWTToken(token))
      dispatch(doLogin())
      dispatch(setUserType(response?.data?.user_type))
      dispatch(setFirstName(response?.data?.first_name))
      dispatch(setLastName(response?.data?.last_name))
      dispatch(
        setFullName(
          `${response?.data?.first_name} ${response?.data?.last_name}`
        )
      )
    } else if (
      authObject?.isAuthenticated &&
      (status === 403 || status === 401)
    ) {
      dispatch(doLogout())
    }
  }
}

function requestConfig({
  headers = {
    Authorization: '',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  baseURL = BASE_API_URL,
  timeout = API_TIMEOUT,
  method = 'GET',
  qs = '',
  data,
  url = '',
  file,
  fileName,
  formData,
  filterPayload,
}) {
  return async (dispatch, getState) => {
    const { auth: authObject } = getState()

    try {
      const fileFormData = new FormData()
      const payload = filterPayload ? filterPayloadData(data) : data
      let querystring = qs
      let payloadData = data
      let newURL = url

      if (authObject?.jwtToken) headers.Authorization = authObject?.jwtToken

      if (file) {
        fileFormData.append('file', payload, fileName)
      }

      if (['GET'].indexOf(method) > -1 && payload)
        querystring = `?${getQueryStringParams(payload)}`
      else if (file) payloadData = fileFormData
      else if (formData) payloadData = payload
      else payloadData = JSON.stringify(payload) // POST PUT PATCH DELETE
      newURL += querystring

      const response = await axios({
        method,
        url: newURL,
        headers,
        data: payloadData,
        baseURL,
        timeout,
        withCredentials: true,
      })

      AuthHandler({ dispatch, response, authObject })

      return response
    } catch (e) {
      AuthHandler({ dispatch, response: e?.response, authObject })
      // eslint-disable-next-line no-console
      console.error('🚀 ~ file: api.js ~ line 59 ~ e', e?.response)
      return e?.response
    }
  }
}

export const doRequest = {
  get: params => rDispatch(requestConfig({ method: 'GET', ...params })),
  post: params => rDispatch(requestConfig({ method: 'POST', ...params })),
  put: params => rDispatch(requestConfig({ method: 'PUT', ...params })),
  patch: params => rDispatch(requestConfig({ method: 'PATCH', ...params })),
  delete: params => rDispatch(requestConfig({ method: 'DELETE', ...params })),
}
