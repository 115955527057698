import { Grid, Typography, Divider } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'

import ButtonBox from 'src/components/button-box'

export default function PreferencesStepForm(props) {
  const { selectedPreferences, setPreferences } = props

  return (
    <ErrorBoundary fallback="Something went wrong">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Preferred Time
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonBox
                icon
                color="secondary"
                selected={selectedPreferences.time.indexOf('24/7') >= 0}
                onClick={() => {
                  setPreferences({
                    ...selectedPreferences,
                    time: ['24/7'],
                  })
                }}
              >
                24/7
              </ButtonBox>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonBox
                icon
                color="secondary"
                selected={selectedPreferences.time.indexOf('Hourly') >= 0}
                onClick={() => {
                  setPreferences({
                    ...selectedPreferences,
                    time: ['Hourly'],
                  })
                }}
              >
                Hourly
              </ButtonBox>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Preferred Gender
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonBox
                icon
                color="secondary"
                selected={selectedPreferences.gender.indexOf('Male') >= 0}
                onClick={() => {
                  setPreferences({
                    ...selectedPreferences,
                    gender: ['Male'],
                  })
                }}
              >
                Male
              </ButtonBox>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonBox
                icon
                color="secondary"
                selected={selectedPreferences.gender.indexOf('Female') >= 0}
                onClick={() => {
                  setPreferences({
                    ...selectedPreferences,
                    gender: ['Female'],
                  })
                }}
              >
                Female
              </ButtonBox>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonBox
                icon
                color="secondary"
                selected={selectedPreferences.gender.indexOf('Either') >= 0}
                onClick={() => {
                  setPreferences({
                    ...selectedPreferences,
                    gender: ['Either'],
                  })
                }}
              >
                Either
              </ButtonBox>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Any Pets?
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonBox
                icon
                color="secondary"
                selected={selectedPreferences.pets.indexOf('Cats') >= 0}
                onClick={() => {
                  setPreferences({
                    ...selectedPreferences,
                    pets: ['Cats'],
                  })
                }}
              >
                Cats
              </ButtonBox>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonBox
                icon
                color="secondary"
                selected={selectedPreferences.pets.indexOf('Dogs') >= 0}
                onClick={() => {
                  setPreferences({
                    ...selectedPreferences,
                    pets: ['Dogs'],
                  })
                }}
              >
                Dogs
              </ButtonBox>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Do you smoke?
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonBox
                icon
                color="secondary"
                selected={selectedPreferences.smoker.indexOf('Yes') >= 0}
                onClick={() => {
                  setPreferences({
                    ...selectedPreferences,
                    smoker: ['Yes'],
                  })
                }}
              >
                Yes
              </ButtonBox>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonBox
                icon
                color="secondary"
                selected={selectedPreferences.smoker.indexOf('No') >= 0}
                onClick={() => {
                  setPreferences({
                    ...selectedPreferences,
                    smoker: ['No'],
                  })
                }}
              >
                No
              </ButtonBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}
