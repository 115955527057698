import { map as _map } from 'lodash'

import LeadTypeButton from './lead-type-button'

import { LeadTypeConfig } from './data'

function LeadTypeButtonList(props) {
  const { leadType, onClick } = props

  return (
    <>
      {_map(leadType, (item, index) => {
        return (
          <LeadTypeButton
            id={LeadTypeConfig[item].key}
            label={LeadTypeConfig[item].label}
            key={LeadTypeConfig[item].key}
            onClick={onClick}
          />
        )
      })}
    </>
  )
}

export default LeadTypeButtonList
