import { forEach as _forEach } from 'lodash'

export function mapToErrorResponse({ response, error, status, callback }) {
  if (error?.message && error?.message.includes('timeout')) {
    callback({ message: error.message, status: 408 })
  } else if (response?.error_details) {
    _forEach(response?.error_details, item => {
      callback({
        message: item?.message || error?.message,
        status: response?.status || status,
      })
    })
  } else {
    callback({
      message: response?.message || error?.message,
      status: response?.status || status,
    })
  }
}
