import { Suspense, lazy } from 'react'
import { useSelector } from 'src/redux/store'

import { AgencyListView } from 'src/features/agency/agency-list-view'
import { AgencyInfoView } from 'src/features/agency/agency-info-view'

const Loadable = Component => props => (
  <Suspense fallback={'Loading...'}>
    <Component {...props} />
  </Suspense>
)

export const LoginPage = Loadable(lazy(() => import('../pages/login')))
export const ChangePasswordPage = Loadable(
  lazy(() => import('../pages/change-password'))
)
export const RegisterPage = Loadable(lazy(() => import('../pages/register')))
export const DashboardPage = Loadable(lazy(() => import('../pages/dashboard')))

export function GetCurrentView() {
  const { currentView } = useSelector(state => state.dashboard)

  switch (currentView) {
    case 'agency-list':
      return <AgencyListView />
    case 'agency-info':
      return <AgencyInfoView />
    default:
      return <AgencyListView />
  }
}
