import React from 'react'
import { Box, Grid, Stepper, Step, StepLabel, Button } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { LoadingButton } from '@mui/lab'

import SelectCareYouNeedStepForm from './select-care-you-need-step-form'
import DescribeYourConditionStepForm from './describe-your-condition-step-form'
import PreferencesStepForm from './preferences-step-form'
import PersonalInfoStepForm from './personal-info-step-form'

const steps = [
  'Select The Care You Need',
  'Describe Your Conditions',
  'Preferences',
  'Personal Information',
]

export default function FindCaregiverForm(props) {
  const { agencyInfo, onSubmit, loading } = props
  const [activeStep, setActiveStep] = React.useState(0)
  const [selectedCare, setSelectedCare] = React.useState([])
  const [selectedConditions, setConditions] = React.useState([])
  const [selectedPreferences, setPreferences] = React.useState({
    time: [],
    gender: [],
    pets: [],
    smoker: [],
  })
  const [validPersonalInfo, setValidPersonalInfo] = React.useState(false)
  const [formValues, setFormValues] = React.useState({
    care_is_for: '',
    gender: '',
    age: '',
    weight: '',
    zip: '',
    name: '',
    email: '',
    phone: '',
  })
  const [validFields, setValidFields] = React.useState({
    care_is_for: false,
    gender: false,
    age: false,
    weight: false,
    zip: agencyInfo?.zip_mandatory ? false : true,
    name: false,
    email: false,
    phone: false,
  })

  const handleValid = React.useCallback(
    (field, value) => {
      const updatedValue = { ...validFields, [field]: value }
      setValidFields({ ...updatedValue })

      setValidPersonalInfo(
        updatedValue.care_is_for &&
          updatedValue.gender &&
          updatedValue.age &&
          updatedValue.weight &&
          updatedValue.zip &&
          updatedValue.name &&
          updatedValue.email &&
          updatedValue.phone
      )
    },
    [setValidPersonalInfo, validFields]
  )

  const handleNext = React.useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }, [])

  const handleBack = React.useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }, [])

  const handleSelectCare = React.useCallback(
    key => {
      const index = selectedCare.indexOf(key)
      if (index === -1) {
        setSelectedCare([...selectedCare, key])
      } else {
        setSelectedCare(selectedCare.filter((_, i) => i !== index))
      }
    },
    [selectedCare]
  )

  const handleSelectCondition = React.useCallback(
    key => {
      const index = selectedConditions.indexOf(key)
      if (index === -1) {
        setConditions([...selectedConditions, key])
      } else {
        setConditions(selectedConditions.filter((_, i) => i !== index))
      }
    },
    [selectedConditions]
  )

  const validateForm = React.useCallback(
    currentStep => {
      if (currentStep === 0) {
        return selectedCare.length > 0
      }
      if (currentStep === 1) {
        return selectedConditions.length > 0
      }
      if (currentStep === 2) {
        return (
          selectedPreferences.time.length > 0 &&
          selectedPreferences.gender.length > 0 &&
          selectedPreferences.pets.length > 0 &&
          selectedPreferences.smoker.length > 0
        )
      }
      if (currentStep === 3) {
        return validPersonalInfo
      }

      return true
    },
    [
      selectedCare.length,
      selectedConditions.length,
      selectedPreferences.gender.length,
      selectedPreferences.pets.length,
      selectedPreferences.smoker.length,
      selectedPreferences.time.length,
      validPersonalInfo,
    ]
  )

  const handleChange = React.useCallback(
    (field, value) => {
      const updatedValue = { ...formValues, [field]: value }
      setFormValues({ ...updatedValue })
    },
    [formValues]
  )

  const handleSubmit = React.useCallback(
    event => {
      event.preventDefault()
      const data = new FormData(event.currentTarget)

      onSubmit({
        type: 'CAREGIVER',
        data: {
          caregiver: {
            personal_details: {
              age: data.get('age'),
              email: data.get('email'),
              first_name: data.get('name').split(' ')[0],
              last_name: data.get('name').split(' ')[1],
              gender: data.get('gender'),
              phone_number: data.get('phone'),
              zip_postal_code: data.get('zip'),
              looking_for: data.get('care_is_for'),
              weight: data.get('weight'),
            },
            search_criteria: {
              pet_comfort: selectedPreferences.pets[0],
              preferred_gender: selectedPreferences.gender[0],
              preferred_time: selectedPreferences.time[0],
              required_care_conditions: {
                required_care_conditions: selectedConditions,
              },
              required_services: {
                required_services: selectedCare,
              },
              smoking_environment: selectedPreferences.smoker[0] === 'Yes',
            },
            source: 'CHAT',
          },
          id: agencyInfo.agency_id,
        },
      })
    },
    [
      agencyInfo.agency_id,
      onSubmit,
      selectedCare,
      selectedConditions,
      selectedPreferences.gender,
      selectedPreferences.pets,
      selectedPreferences.smoker,
      selectedPreferences.time,
    ]
  )

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ErrorBoundary fallback="Something went wrong">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box mb={1} />
          {/* ========================== Select the care you need ========================== */}
          {activeStep === 0 && (
            <SelectCareYouNeedStepForm
              selectedCare={selectedCare}
              handleSelectCare={handleSelectCare}
            />
          )}
          {/* ========================== Describe your conditions ========================== */}
          {activeStep === 1 && (
            <DescribeYourConditionStepForm
              selectedConditions={selectedConditions}
              handleSelectCondition={handleSelectCondition}
            />
          )}
          {/* ========================== Preferences ========================== */}
          {activeStep === 2 && (
            <PreferencesStepForm
              selectedPreferences={selectedPreferences}
              setPreferences={setPreferences}
            />
          )}
          {/* ========================== Personal Information ========================== */}
          {activeStep === 3 && (
            <PersonalInfoStepForm
              handleValid={handleValid}
              handleChange={handleChange}
              formValues={formValues}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ErrorBoundary fallback="Something went wrong">
            <Box display="flex" justifyContent="space-between">
              <Button
                onClick={handleBack}
                disabled={activeStep === 0}
                variant="contained"
                fullWidth
              >
                Prev
              </Button>
              <Box width={120} />
              {activeStep < steps.length - 1 ? (
                <Button
                  onClick={handleNext}
                  variant="contained"
                  fullWidth
                  disabled={validateForm(activeStep) === false}
                >
                  Next
                </Button>
              ) : (
                <LoadingButton
                  autoFocus={false}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={validateForm(activeStep) === false}
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              )}
            </Box>
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Box>
  )
}
