import { Button, Grid } from '@mui/material'
import { isFunction as _isFunction, upperCase as _upperCase } from 'lodash'

function LeadTypeButton(props) {
  const { label, id, onClick } = props
  return (
    <Grid item xs={12} sm={6} md={6}>
      <Button
        size="large"
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => {
          if (_isFunction(onClick)) {
            onClick(id)
          }
        }}
      >
        {_upperCase(label)}
      </Button>
    </Grid>
  )
}

export default LeadTypeButton
