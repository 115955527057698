// import { format, getTime, formatDistanceToNow } from 'date-fns'

// export function fDate(date, newFormat) {
//   const fm = newFormat || 'dd MMM yyyy'

//   return date ? format(new Date(date), fm) : ''
// }

// export function fDateTime(date, newFormat) {
//   const fm = newFormat || 'dd MMM yyyy p'

//   return date ? format(new Date(date), fm) : ''
// }

// export function fTimestamp(date) {
//   return date ? getTime(new Date(date)) : ''
// }

// export function fToNow(date) {
//   return date
//     ? formatDistanceToNow(new Date(date), {
//         addSuffix: true,
//       })
//     : ''
// }

export function formatDateTimeForPayload(value) {
  if (!value) {
    return null
  }

  const padZero = num => (num < 10 ? `0${num}` : num)

  const year = value?.$y
  const month = padZero(value?.$M + 1)
  const day = padZero(value?.$D)
  const hour = padZero(value?.$H)
  const minute = padZero(value?.$m)

  return `${year}-${month}-${day}T${hour}:${minute}`
}

export function formatDateForPayload(value) {
  if (!value) {
    return null
  }

  const padZero = num => (num < 10 ? `0${num}` : num)

  const year = value?.$y
  const month = padZero(value?.$M + 1)
  const day = padZero(value?.$D)

  return `${year}-${month}-${day}`
}
