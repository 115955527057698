import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  agencyInfo: null,
  agencies: { group_name: null, agencies: null },
  selectedAgency: null,
  currentView: null,
}

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },
    setAgencyInfo(state, action) {
      state.agencyInfo = action.payload
    },
    setAgencies(state, action) {
      state.agencies = action.payload
    },
    setSelectedAgency(state, action) {
      state.selectedAgency = action.payload
    },
    setCurrentView(state, action) {
      state.currentView = action.payload
    },
  },
})

export const dashboardReducer = slice.reducer

export const {
  startLoading,
  stopLoading,
  setAgencyInfo,
  setAgencies,
  setSelectedAgency,
  setCurrentView,
} = slice.actions
