import * as React from 'react'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Container } from '@mui/material'

import { APPLICATION_NAME } from 'src/config-global'
import { dispatch, useSelector } from 'src/redux/store'
import { useRouter } from 'src/routes/hooks'
import { doLogout } from 'src/features/auth/slices'

import FindAgencyDialog from 'src/features/agency/find-agency-dialog'

const drawerWidth = 240
const navItems = ['Home', 'About', 'Contact']

function DrawerAppBar(props) {
  const { window } = props
  const {
    agencies: { group_name },
    selectedAgency,
  } = useSelector(state => state.dashboard)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const router = useRouter()
  const [openFindAgencyDialog, setOpenFindAgencyDialog] = React.useState(false)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = React.useCallback(() => {
    dispatch(doLogout())
    setAnchorEl(null)
  }, [])

  const handleChangePassword = React.useCallback(() => {
    setAnchorEl(null)
    router.push('/auth-protected/change-password')
  }, [router])

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }

  const handleFindAgencyDialogAction = React.useCallback(() => {
    setOpenFindAgencyDialog(prevState => !prevState)
    handleClose()
  }, [])

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {APPLICATION_NAME}
      </Typography>
      <Divider />
      <List>
        {navItems.map(item => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" color="primary">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
              <Typography
                variant="h4"
                component="h1"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
              >
                {selectedAgency?.agency_name || group_name || APPLICATION_NAME}
              </Typography>
              {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map(item => (
              <Button key={item} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box> */}
              <Box>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleFindAgencyDialogAction}>
                    Find Agency
                  </MenuItem>
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleChangePassword}>
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <FindAgencyDialog
        isOpen={openFindAgencyDialog}
        handleAction={handleFindAgencyDialogAction}
      />
    </React.Fragment>
  )
}

export default DrawerAppBar
