import React from 'react'
import { Box, Grid, Typography, TextField, Autocomplete } from '@mui/material'
import * as Styled from './styles'
import { dispatch, useSelector } from 'src/redux/store'
import { setSelectedAgency, setCurrentView } from 'src/slices/dashboard-slice'
import { setStoreParams } from 'src/slices/stored-params-slice'

export function AgencyListView(props) {
  const {
    agencies: { group_name, agencies: agencyList },
  } = useSelector(state => state.dashboard)
  const { storedParams } = useSelector(state => state.storedParams)

  const [value, setValue] = React.useState(null)

  const handleChange = React.useCallback(
    (event, newValue) => {
      setValue(newValue)
      dispatch(setSelectedAgency(newValue))
      dispatch(
        setStoreParams({ ...storedParams, i: newValue.agency_id, g: null })
      )
      dispatch(setCurrentView('agency-info'))
    },
    [storedParams]
  )

  return (
    <Styled.StyledMainViewContainer>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {storedParams?.g ? (
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" gutterBottom>
              Select Agency For {group_name} Group
            </Typography>
            <Box p={1} />
            {agencyList && agencyList.length > 0 && (
              <Autocomplete
                id="agency-autocomplete"
                value={value}
                onChange={handleChange}
                options={agencyList}
                getOptionLabel={option => option.agency_name}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Agency"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h5" textAlign="center" gutterBottom>
              No Agencies Found
            </Typography>
          </Grid>
        )}
      </Grid>
    </Styled.StyledMainViewContainer>
  )
}
