import React from 'react'
import { TextField, MenuItem, InputAdornment, IconButton } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { map as _map } from 'lodash'

const inputProps = {}

export default function InputField(props) {
  const {
    name,
    label,
    value = '',
    valid = true,
    type,
    errorMessage,
    onChange = () => { },
    onValid = () => { },
    validRegex,
    select,
    options,
    required,
    defaultValue,
    ...other
  } = props
  const [inputValue, setInputValue] = React.useState(value || defaultValue)
  const [isValid, setValid] = React.useState(valid)
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleChange = React.useCallback(
    event => {
      onChange(event.target.value)
      setInputValue(event.target.value)

      const isValidInput = validRegex
        ? event.target.value.match(validRegex)
        : event.target.value
      const isEmptyAndNotRequired = event.target.value === '' && !required

      if (isValidInput || isEmptyAndNotRequired) {
        setValid(true)
      } else {
        setValid(false)
      }

      onValid(isValidInput)
    },
    [onChange, onValid, required, validRegex]
  )

  inputProps.endAdornment = null

  if (type === 'password') {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  }

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="dense"
      value={inputValue}
      label={label}
      name={name}
      onChange={handleChange}
      select={select}
      required={required}
      type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
      InputProps={{
        ...inputProps,
      }}
      {...other}
      {...(!isValid && { error: true, helperText: errorMessage })}
    >
      {select &&
        options &&
        _map(options, option => (
          <MenuItem
            key={`${option.value || option}_${React.useId()}`}
            value={option.value || option}
          >
            {option.label || option}
          </MenuItem>
        ))}
    </TextField>
  )
}
