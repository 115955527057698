import React from 'react'
import { Grid } from '@mui/material'

import PhoneMaskInputField from 'src/components/phone-mask-input-field'
import ZipMaskInputField from 'src/components/zip-mask-input-field'
import InputField from 'src/components/form-fields/input-field'

export default function PersonalInfoStepForm(props) {
  const { handleValid, handleChange, formValues } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <InputField
          margin="none"
          name="care_is_for"
          label="Care is for?"
          type="select"
          select
          options={['Self', 'Parent(s)', 'Other']}
          errorMessage="Please select Care is for"
          onValid={value => handleValid('care_is_for', value)}
          value={formValues.care_is_for}
          onChange={value => handleChange('care_is_for', value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InputField
          margin="none"
          name="gender"
          label="Gender"
          type="select"
          select
          options={['Male', 'Female']}
          errorMessage="Please select gender"
          onValid={value => handleValid('gender', value)}
          value={formValues.gender}
          onChange={value => handleChange('gender', value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InputField
          name="age"
          label="Age"
          type="text"
          margin="none"
          onValid={value => handleValid('age', value)}
          value={formValues.age}
          onChange={value => handleChange('age', value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InputField
          name="weight"
          label="Weight"
          type="text"
          margin="none"
          onValid={value => handleValid('weight', value)}
          value={formValues.weight}
          onChange={value => handleChange('weight', value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InputField
          margin="none"
          name="zip"
          label="Zip Code where care is needed"
          type="text"
          validRegex={/^[a-zA-Z0-9]{4,16}$/}
          errorMessage="Please enter a valid Zip Code"
          onValid={value => handleValid('zip', value)}
          InputProps={{
            inputComponent: ZipMaskInputField,
          }}
          value={formValues.zip}
          onChange={value => handleChange('zip', value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InputField
          name="name"
          label="Contact Name"
          type="text"
          margin="none"
          onValid={value => handleValid('name', value)}
          value={formValues.name}
          onChange={value => handleChange('name', value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InputField
          margin="none"
          name="email"
          label="Email"
          type="email"
          validRegex={/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/}
          errorMessage="Please enter a valid email"
          onValid={value => handleValid('email', value)}
          value={formValues.email}
          onChange={value => handleChange('email', value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InputField
          margin="none"
          name="phone"
          label="Phone"
          type="tel"
          validRegex={/^[0-9]{10,}$/}
          errorMessage="Please enter a valid phone number"
          onValid={value => handleValid('phone', value)}
          InputProps={{
            inputComponent: PhoneMaskInputField,
          }}
          value={formValues.phone}
          onChange={value => handleChange('phone', value)}
        />
      </Grid>
    </Grid>
  )
}
