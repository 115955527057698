import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function MultiSelectField(props) {
  const {
    name,
    label,
    value = [],
    valid = true,
    errorMessage,
    onChange = () => {},
    onValid = () => {},
    options,
    ...other
  } = props
  const [inputValue, setInputValue] = React.useState(value)
  const [isValid, setValid] = React.useState(valid)

  const handleChange = React.useCallback(
    event => {
      onChange(
        typeof event.target.value === 'string'
          ? event.target.value.split(',')
          : event.target.value
      )
      setInputValue(
        typeof event.target.value === 'string'
          ? event.target.value.split(',')
          : event.target.value
      )
      if (event.target.value) {
        setValid(true)
        onValid(true)
      } else {
        if (isValid) {
          setValid(false)
          onValid(false)
        }
      }
    },
    [isValid, onChange, onValid]
  )

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id={`multi-select-field-label-${React.useId()}`}>
        {label}
      </InputLabel>
      <Select
        labelId={`multi-select-field-label-${React.useId()}`}
        id={`multi-select-field-${React.useId()}`}
        multiple
        value={inputValue}
        onChange={handleChange}
        name={name}
        input={<OutlinedInput label={label} name={name} />}
        renderValue={selected => selected.join(', ')}
        MenuProps={MenuProps}
        {...other}
      >
        {options.map(option => (
          <MenuItem
            key={`${option.value || option}_${React.useId()}`}
            value={option.value || option}
          >
            <Checkbox checked={inputValue.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
