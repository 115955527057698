import { IMaskInput } from 'react-imask'
import React from 'react'

const PhoneMaskInputField = React.forwardRef(
  function PhoneMaskInputField(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        unmask={true}
        onAccept={value =>
          onChange({
            target: { name: props.name, value, required: props?.required },
          })
        }
        overwrite
      />
    )
  }
)

export default PhoneMaskInputField
