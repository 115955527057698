import React from 'react'
import { Box, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import PhoneMaskInputField from 'src/components/phone-mask-input-field'
import ZipMaskInputField from 'src/components/zip-mask-input-field'
import AutoCompleteField from 'src/components/form-fields/auto-complete-field'

import InputField from 'src/components/form-fields/input-field'
import { formatDateTimeForPayload } from 'src/utils/format-time'
import { COUNTRY_ZIP_CODE_DETAILS, COUNTRY_ZIP_CODE_REGEX } from './data'

export default function ClientLeadForm(props) {
  const { agencyInfo, sessionValue, onSubmit, loading } = props
  const [validForm, setValidForm] = React.useState(false)
  const [validFields, setValidFields] = React.useState({
    name: false,
    email: false,
    phone: false,
    care: false,
    zip: false,
    interview_time: false,
    time_zone: false,
    note: false,
    chat_transcript: false,
  })
  const [formValues, setFormValues] = React.useState({
    name: null,
    email: null,
    phone: null,
    care: null,
    zip: null,
    interview_time: null,
    time_zone: null,
    note: null,
    chat_transcript: null,
  })

  React.useEffect(() => {
    let validFormFlag =
      validFields.name ||
      validFields.email ||
      validFields.phone ||
      validFields.care ||
      validFields.zip ||
      validFields.interview_time ||
      validFields.time_zone ||
      validFields.note ||
      validFields.chat_transcript

    if (agencyInfo?.zip_mandatory) {
      validFormFlag = validFormFlag && validFields.zip
    }

    const fields = ['email', 'phone', 'zip']

    fields.forEach(field => {
      if (formValues[field]) {
        validFormFlag = validFormFlag && validFields[field]
      }
    })

    setValidForm(validFormFlag)
  }, [
    agencyInfo?.zip_mandatory,
    formValues,
    formValues.email,
    formValues.interview_time,
    formValues.phone,
    formValues.zip,
    validFields,
  ])

  const handleValid = React.useCallback(
    (field, value) => {
      setValidFields({ ...validFields, [field]: value })
    },
    [validFields]
  )

  const handleSubmit = React.useCallback(
    event => {
      event.preventDefault()
      const data = new FormData(event.currentTarget)
      const leadData = {
        name: data.get('name'),
        email: data.get('email'),
        phone_number: data.get('phone'),
        care_is_for: data.get('care'),
        zip_where_care_needed: data.get('zip'),
        message: data.get('note'),
        assessment_date_time: formValues?.interview_time?.$d
          ? formatDateTimeForPayload(formValues?.interview_time)
          : null,
        time_zone: data.get('time_zone'),
        chat_transcript: data.get('chat_transcript'),
        agency_id: agencyInfo.agency_id,
        lead_type: 'CLIENT',
        params: sessionValue,
      }
      if (
        leadData.assessment_date_time === null ||
        leadData.assessment_date_time === undefined ||
        leadData.assessment_date_time === ''
      ) {
        delete leadData.time_zone
      }

      onSubmit({
        type: 'LEAD',
        data: {
          leads: {
            ...leadData,
          },
        },
      })
    },
    [agencyInfo.agency_id, formValues, onSubmit, sessionValue]
  )

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <InputField
            name="name"
            label="Full Name"
            type="text"
            margin="none"
            onValid={value => handleValid('name', value)}
            onChange={value => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            margin="none"
            name="email"
            label="Email"
            type="email"
            validRegex={/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/}
            errorMessage="Please enter a valid email"
            onValid={value => handleValid('email', value)}
            onChange={value => setFormValues({ ...formValues, email: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            margin="none"
            name="phone"
            label="Phone"
            type="tel"
            validRegex={/^[0-9]{10,}$/}
            errorMessage="Please enter a valid phone number"
            onValid={value => handleValid('phone', value)}
            onChange={value => setFormValues({ ...formValues, phone: value })}
            InputProps={{
              inputComponent: PhoneMaskInputField,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AutoCompleteField
            margin="none"
            name="care"
            label="Care is for"
            options={['Self', 'Parents', 'Other']}
            errorMessage="Please select a care type"
            onValid={value => handleValid('care', value)}
            onChange={value => setFormValues({ ...formValues, care: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InputField
            margin="none"
            name="zip"
            label={agencyInfo?.country === 'CANADA' ? "Postal Code where care is needed" : "Zip Code where care is needed"}
            type="text"
            validRegex={COUNTRY_ZIP_CODE_DETAILS[agencyInfo?.country]?.zipCodeRegexp}
            errorMessage={agencyInfo?.country === 'CANADA' ? "Please enter a valid Postal Code" : "Please enter a valid Zip Code"}
            onValid={value => {
              handleValid('zip', value)
            }}
            onChange={value => setFormValues({ ...formValues, zip: value })}
            InputProps={{
              inputComponent: ZipMaskInputField,
              inputProps: {
                mask: COUNTRY_ZIP_CODE_DETAILS[agencyInfo?.country]?.mask,
                directionRegexp: COUNTRY_ZIP_CODE_DETAILS[agencyInfo?.country]?.directionRegexp,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DateTimePicker
            sx={{ width: '100%' }}
            format="MM/DD/YYYY hh:mm a"
            name="interview_time"
            label="Assessment / Interview Time"
            onValid={value => handleValid('interview_time', value)}
            onChange={value =>
              setFormValues({ ...formValues, interview_time: value })
            }
          // value={dateValue}
          // onChange={newValue => setDateValue(newValue)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <AutoCompleteField
            margin="none"
            name="time_zone"
            label="Time Zone"
            value={agencyInfo?.office_hours_timezone}
            options={['AST', 'CST', 'EST', 'HST', 'MST', 'PST']}
            errorMessage="Please select Time Zone"
            onValid={value => handleValid('time_zone', value)}
            onChange={value =>
              setFormValues({ ...formValues, time_zone: value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            margin="none"
            name="note"
            label="Agent Note"
            type="text"
            multiline
            rows={4}
            onValid={value => handleValid('note', value)}
            onChange={value => setFormValues({ ...formValues, note: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            margin="none"
            name="chat_transcript"
            label="Chat Transcript"
            type="text"
            multiline
            rows={4}
            onValid={value => handleValid('chat_transcript', value)}
            onChange={value =>
              setFormValues({ ...formValues, chat_transcript: value })
            }
          />
        </Grid>
      </Grid>
      <Box mb={2} />
      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        disabled={!validForm}
      >
        Submit
      </LoadingButton>
    </Box>
  )
}
