import { createBrowserRouter, Navigate } from 'react-router-dom'
import { RouterProvider } from 'react-router-dom'
import { LoginPage, DashboardPage, ChangePasswordPage } from './elements'
import { DashboardLayout, AuthLayout } from '../layouts'
import { PATH_AFTER_LOGIN } from '../config-global'
import { PATH_AUTH } from './paths'
import AuthGuard from 'src/features/auth/guard/auth-guard'
import GuestGuard from 'src/features/auth/guard/guest-guard'
import { dispatch, useSelector } from 'src/redux/store'
import { setStoreParams } from 'src/slices/stored-params-slice'
import { isEqual as _isEqual } from 'lodash'

export default function Routes() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to="/auth" replace />,
      index: true,
      loader: ({ params, request }) => {
        const { storedParams } = useSelector(state => state.storedParams)

        // Use destructuring to get URL parameters directly
        const { search } = window.location
        const urlParams = Object.fromEntries(new URLSearchParams(search))

        // Merge URL parameters with stored parameters
        const paramVal = Object.entries(urlParams).reduce((acc, [key, val]) => {
          return {
            ...acc,
            [key]: val || storedParams?.[key] || null,
          }
        }, {})

        // Check if 'g' exists in paramVal and set 'i' to null
        if (paramVal?.g) {
          paramVal.i = null
        }

        console.log('🚀 ~ Routes ~ paramVal:', paramVal)

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            // Check if paramVal and storedParams are not equal and paramVal is not empty
            if (
              !_isEqual(paramVal, storedParams) &&
              Object.keys(paramVal).length
            ) {
              dispatch(setStoreParams(paramVal))
            }
            resolve({ status: 200 })
          }, 100)
        })
      },
    },
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AUTH.login} replace />, index: true },
        {
          path: 'login',
          element: <LoginPage />,
        },
      ],
    },
    {
      path: 'auth-protected',
      element: (
        <AuthGuard>
          <AuthLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={'/auth-protected/change-password'} replace />,
          index: true,
        },
        {
          path: 'change-password',
          element: <ChangePasswordPage />,
        },
      ],
    },
    {
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'base', element: <DashboardPage /> },
        {
          path: 'change-password',
          element: <Navigate to={'/auth-protected/change-password'} replace />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}
